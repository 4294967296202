import * as React from "react";
import { v4 as uuidv4 } from "uuid";
import { EditorState } from "draft-js";
import { useTranslation } from "react-i18next";
import { SelectChangeEvent } from "@mui/material/Select";

import agent from "src/helpers/apiAgent";

import useCourse from "src/hooks/useCourse";
import useResolvers from "src/hooks/useResolvers";

import { Generic } from "src/@types/Category";

import { errorHandler, successHandler } from "src/helpers/responseHandler";

export const DEFAULT_ASSIGNMENT_DATA = {
  title: "",
  type: 1,
  subType: 1,
  publishDate: new Date(),
  dueDate: new Date(),
  courseId: "",
  marks: 0,
  minMarks: 0,
  weightage: 0,
  difficulty: 1,
  status: 1,
  tags: [],
  time: 0,
  questions: [],
  questionMarks: [],
  sections: [
    {
      id: uuidv4(),
      title: "",
      subtitle: "",
      questions: [],
    },
  ],
  description: "",
  descriptionRaw: EditorState.createEmpty(),
  autoGradeable: false,
};

const DEFAULT_FILTERS = {
  tags: [], // multi
  author: [],
  courseId: "", // courseCode
  keywords: [], // "%solve for x%"
  count: [0, 0],
  questionType: [], // multi
  questionDificulty: [], // multi
};

const DEFAULT_AUTO_QUESTIONS = {
  numberOfQuestions: 5,
  courseId: "",
  // "keywords": ["%solve for x%"],
};

const DEFAULTS = {
  loading: false,
  isFiltered: false,
  tagsData: [],
  questions: [],
  selectedTags: [],
  assignmentId: "",
  courseWeightage: 0,
  questionsAuthors: [],
  resetFilter: () => {},
  selectedFilterTags: [],
  addNewSection: () => {},
  removeSection: () => {},
  setRefetch: (val: Generic) => {},
  setIsFiltered: (val: Generic) => {},
  questionsFilters: DEFAULT_FILTERS,
  autoQuesions: DEFAULT_AUTO_QUESTIONS,
  assignmentData: DEFAULT_ASSIGNMENT_DATA,
  bulkQuestionSelectionForSection: [],
  assignmentStudents: { studentIds: [], all: true }, // // Setting by default all as have only one option rn
  handleTagChange: (e: SelectChangeEvent<Array<string>>) => {},
  handleTagDelete: (e: SelectChangeEvent, val: string) => {},
  handleFilterMultiChange: (
    e: SelectChangeEvent<Array<string>>,
    key: string
  ) => {},
  handleFilterMultiDelete: (
    e: SelectChangeEvent,
    val: string,
    key: string
  ) => {},
  filterQuestions: () => {},
  createAssignment: () => false,
  updateAssignment: (activeStep: number) => false,
  publishAssignment: () => false,
  updateSectionData: () => false,
  handleAutoQuestions: () => {},
  setAutoQuesions: (data: Generic) => {},
  setAssignmentData: (data: Generic) => {},
  setQuestionsFilters: (data: Generic) => {},
  resetAssignmentData: () => {},
  editDraftedAssignment: (data: Generic) => {},
  setAssignmentStudents: (data: Generic) => {},
  selectedQuestionsCount: (data: Generic) => {},
  handleQuestionSelection: (data: Generic) => {},
  clearbulkQuestionSelectionForSection: () => {},
  handleChangeQuestionMarks: (data: Generic) => {},
  handleBulkQuestionSelectionForSection: (questionId: string) => {},
  addBulkQuestionsToSection: (sectionId: string, callback: () => void) => {},
};

const NewAssignmentContext = React.createContext(DEFAULTS);

const NewAssignmentProvider: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
}) => {
  const { t } = useTranslation();
  const {
    // QuestionTypes,
    AssessmentTypesResolver,
  } = useResolvers();

  const { categories, activeCourseId: courseCode, courses } = useCourse();

  const [loading, setLoading] = React.useState(false);
  const [refetch, setRefetch] = React.useState(false);
  const [isFiltered, setIsFiltered] = React.useState(false);
  const [tagsData, setTagsData] = React.useState([]);
  const [bulkQuestionSelectionForSection, setBulkQuestionSelectionForSection] =
    React.useState([]);
  const [questionsFilters, setQuestionsFilters] =
    React.useState(DEFAULT_FILTERS);
  const [autoQuesions, setAutoQuesions] = React.useState(
    DEFAULT_AUTO_QUESTIONS
  );
  const [questionsAuthors, setQuestionAuthors] = React.useState([]);
  const [assignmentStudents, setAssignmentStudents] = React.useState({
    studentIds: [],
    all: true, // Setting by default all as have only one option rn
  });
  const [selectedQuestionsCount, setSelectedQuestionsCounts] = React.useState({
    mcq: 0,
    pictorial: 0,
    whiteBoard: 0,
    descriptive: 0,
  });
  const [assignmentData, setAssignmentData] = React.useState(
    DEFAULTS?.assignmentData
  );
  const [assignmentId, setAssignmentId] = React.useState("");
  const [selectedTags, setSelectedTags] = React.useState([]);
  const [courseWeightage, setCourseWeightage] = React.useState(0);
  const [courseQuestions, setCourseQuestions] = React.useState([]);
  const [filteredQuestion, setFilteredQuestion] = React.useState([]);
  const [selectedFilterTags, setSelectedFilterTags] = React.useState([]);

  const resetAssignmentData = () => {
    setAutoQuesions((prev) => ({
      ...DEFAULT_AUTO_QUESTIONS,
      courseId: prev?.courseId,
    }));
    setAssignmentData(DEFAULT_ASSIGNMENT_DATA);
    setAssignmentId("");
  };

  const resetFilter = () => {
    setIsFiltered(false);
    // Persisting courseId
    setQuestionsFilters((prev) => ({
      ...DEFAULT_FILTERS,
      courseId: prev?.courseId,
    }));
  };

  const editDraftedAssignment = (assignment) => {
    if (assignment?.questionMarks) {
      setAssignmentData({ ...assignment });
    } else {
      setAssignmentData({ ...assignment, questionMarks: [] });
    }
    setAssignmentId(assignment?.id);
    // Persisting courseId
    setAutoQuesions((prev) => ({
      ...DEFAULT_AUTO_QUESTIONS,
      courseId: prev?.courseId,
    }));
    // setAssignmentStudents
  };

  // Handling assignment of couseCode to Question Filters and Question Auto Select,
  // For New Assignment and Drafted assignment for editing
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const courseCodeValue = assignmentData?.Course?.courseCode ?? courseCode;

    if (courseCodeValue && questionsFilters?.courseId !== courseCodeValue) {
      setQuestionsFilters((prev) => ({
        ...prev,
        courseId: courseCodeValue,
      }));
      setAutoQuesions((prev) => ({
        ...prev,
        courseId: courseCodeValue,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseCode, assignmentData]);

  React.useEffect(() => {
    const getCourseQuestionsAndWeightageDetails = async () => {
      try {
        if (courseCode) {
          const course = courses?.find(
            (c) => c?.id === courseCode || c?.courseCode === courseCode
          );
          if (Object.keys(course)?.length) {
            const questions = await agent.Questions.getQuestionsByCourseId(
              course?.courseCode
            );
            const weightage = await agent.Assessments.getAssessmentWeightage(
              course?.id
            );

            if (questions.code === 200) {
              setCourseQuestions(questions?.data);
            }
            if (weightage.code === 200) {
              setCourseWeightage(weightage?.data);
            }
          }
        }
      } catch (error) {}
    };
    getCourseQuestionsAndWeightageDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseCode, refetch, courses]);

  // Setting course categories to display in select
  React.useEffect(() => {
    const courseId = courses?.find(
      (c) => c?.id === courseCode || c?.courseCode === courseCode
    )?.id;
    setTagsData(categories[courseId]?.categories ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, courseCode]);

  // Setting selected categories to display tag pills
  React.useEffect(() => {
    const _categories = tagsData?.filter((tag) =>
      assignmentData?.tags?.includes(tag?.id)
    );
    setSelectedTags(_categories);
  }, [assignmentData?.tags, courseCode, tagsData]);

  React.useEffect(() => {
    const _categories = tagsData?.filter((tag) =>
      questionsFilters?.tags?.includes(tag?.id)
    );
    setSelectedFilterTags(_categories);
  }, [questionsFilters?.tags, courseCode, tagsData]);

  // Setting selected questions counts
  React.useEffect(() => {
    const mcq =
      assignmentData?.questions?.filter((d) =>
        courseQuestions?.some((t) => t.id === d && t.questionType === 1)
      ).length || 0;
    const descriptive =
      assignmentData?.questions?.filter((d) =>
        courseQuestions?.some((t) => t.id === d && t.questionType === 2)
      ).length || 0;
    const pictorial =
      assignmentData?.questions?.filter((d) =>
        courseQuestions?.some((t) => t.id === d && t.questionType === 3)
      ).length || 0;
    const whiteBoard =
      assignmentData?.questions?.filter((d) =>
        courseQuestions?.some((t) => t.id === d && t.questionType === 4)
      ).length || 0;

    setSelectedQuestionsCounts({ mcq, descriptive, pictorial, whiteBoard });
  }, [assignmentData, courseQuestions]);

  // Assignment Tags Starts
  // const handleTagChange = (event: SelectChangeEvent<typeof selectedTags>) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   console.log({ value });
  //   const identity = value[value?.length - 1]?.id ?? value[value?.length - 1];
  //   console.log({ identity });

  //   const index = assignmentData?.tags?.indexOf(identity, 0);
  //   if (index > -1) {
  //     const updated = [...assignmentData?.tags];
  //     updated.splice(index, 1);
  //     setAssignmentData((prev) => ({ ...prev, tags: updated }));
  //   } else {
  //     setAssignmentData((prev) => ({
  //       ...prev,
  //       tags: [...assignmentData?.tags, identity],
  //     }));
  //   }
  // };
  const handleTagChange = (values: Array<string>) =>
    setAssignmentData((prev) => ({ ...prev, tags: values }));

  const handleTagDelete = (e, value) => {
    e.stopPropagation();
    const assignmentTags = [...assignmentData.tags];
    const removeItemIndex = assignmentTags.indexOf(value);
    assignmentTags?.splice(removeItemIndex, 1);
    setAssignmentData((prev) => ({
      ...prev,
      tags: [...assignmentTags],
    }));
  };

  // Assignment Filter Tags
  const handleFilterMultiChange = (
    event: SelectChangeEvent<typeof selectedTags>,
    key: "tags" | "questionType" | "questionDificulty" | "author"
  ) => {
    const {
      target: { value },
    } = event;
    const identity = value[value?.length - 1]?.id ?? value[value?.length - 1];

    const index = questionsFilters?.[key]?.indexOf(identity, 0);
    if (index > -1) {
      const updated = [...questionsFilters?.[key]];
      updated.splice(index, 1);
      setQuestionsFilters((prev) => ({ ...prev, [key]: updated }));
    } else {
      setQuestionsFilters((prev) => ({
        ...prev,
        [key]: [...questionsFilters?.[key], identity],
      }));
    }
  };

  const handleFilterMultiDelete = (e, value, key) => {
    e.stopPropagation();
    const copyOfFilters = [...questionsFilters?.[key]];
    const removeItemIndex = copyOfFilters.indexOf(value);
    copyOfFilters?.splice(removeItemIndex, 1);
    setQuestionsFilters((prev) => ({
      ...prev,
      [key]: [...copyOfFilters],
    }));
  };
  // Assignment Filter Tags Ends

  // Assignment Questions Starts
  const handleQuestionSelection = (questionId) => {
    const index = assignmentData?.questions?.indexOf(questionId);
    if (index > -1) {
      const updated = [...assignmentData?.questions];
      updated.splice(index, 1);
      setAssignmentData((prev) => ({ ...prev, questions: updated }));
    } else {
      setAssignmentData((prev) => ({
        ...prev,
        questions: [...assignmentData?.questions, questionId],
      }));
    }
  };
  // Assignment Questions Ends

  // Assignment Structure Starts
  const addNewSection = () => {
    setAssignmentData((prev) => ({
      ...prev,
      sections: [
        ...assignmentData?.sections,
        {
          id: uuidv4(),
          title: "",
          subtitle: "",
          questions: [],
        },
      ],
    }));
  };

  const removeSection = (sectionId) => {
    const index = assignmentData?.sections?.findIndex(
      (s) => s?.id === sectionId
    );
    if (index > -1) {
      const updated = [...assignmentData?.sections];
      updated.splice(index, 1);
      setAssignmentData((prev) => ({ ...prev, sections: updated }));
    }
  };

  const updateSectionData = (sectionId, field, value) => {
    // Check for section to exist
    const index = assignmentData?.sections?.findIndex(
      (s) => s?.id === sectionId
    );
    if (index > -1 && field !== "questions") {
      // If field is not "questions" & we have this section already
      const section = JSON.parse(
        JSON.stringify(assignmentData?.sections[index])
      );
      section[field] = value;
      assignmentData.sections[index] = section;
      setAssignmentData((prev) => ({ ...prev, ...assignmentData }));
    } else if (index > -1) {
      // Remove occurances of question in other sections if any
      const indexQuestion = assignmentData?.sections?.findIndex((s) =>
        s.questions?.includes(value)
      );
      if (indexQuestion > -1) {
        const questions = assignmentData?.sections[indexQuestion]?.questions;
        questions?.splice(questions.indexOf(value), 1);
        assignmentData.sections[indexQuestion].questions = questions;
      }
      // If field is "questions", now insert question to this section
      const section = JSON.parse(
        JSON.stringify(assignmentData?.sections[index])
      );
      section[field] = [...section[field], value];
      assignmentData.sections[index] = section;
      setAssignmentData((prev) => ({ ...prev, ...assignmentData }));
    }
  };

  const handleBulkQuestionSelectionForSection = (questionId) => {
    if (!bulkQuestionSelectionForSection?.includes(questionId)) {
      setBulkQuestionSelectionForSection((prev) => [...prev, questionId]);
    } else {
      const copy = JSON.parse(JSON.stringify(bulkQuestionSelectionForSection));
      copy.splice(copy.indexOf(questionId), 1);
      setBulkQuestionSelectionForSection(copy);
    }
  };

  const clearbulkQuestionSelectionForSection = () =>
    setBulkQuestionSelectionForSection([]);

  const addBulkQuestionsToSection = (
    sectionId: string,
    callback: () => void
  ) => {
    // TODO: Handle removing of questions from others sections if duplicated
    const index = assignmentData?.sections?.findIndex(
      (s) => s?.id === sectionId
    );
    if (index > -1 && bulkQuestionSelectionForSection?.length) {
      const updatedSections = [...assignmentData?.sections];

      // First remove those questions if in anyother section already
      updatedSections?.forEach(
        (section) =>
          (section.questions = section?.questions?.filter(
            (question) => !bulkQuestionSelectionForSection.includes(question)
          ))
      );

      // Updating sections with selected questions
      updatedSections[index] = {
        ...updatedSections[index],
        questions: [
          ...updatedSections[index]?.questions,
          ...bulkQuestionSelectionForSection,
        ],
      };
      setAssignmentData((prev) => ({ ...prev, sections: updatedSections }));
      successHandler(
        `Question${
          bulkQuestionSelectionForSection?.length > 1 ? "s" : ""
        } moved to section!`
      );

      // Resetting fields
      clearbulkQuestionSelectionForSection();
      if (callback) {
        callback();
      }
    }
  };

  const handleChangeQuestionMarks = (questionId, marks) => {
    const index = assignmentData?.questionMarks?.findIndex(
      (q) => q?.questionId === questionId
    );
    if (index > -1) {
      const original = [...assignmentData?.questionMarks];
      const toUpdate = original[index];
      toUpdate.marks = Number(marks);
      original[index] = toUpdate;
      setAssignmentData((prev) => ({ ...prev, questionMarks: original }));
    } else {
      setAssignmentData((prev) => ({
        ...prev,
        questionMarks: [
          ...assignmentData?.questionMarks,
          { questionId, marks },
        ],
      }));
    }
  };
  // Assignment Structure Ends

  // Validations Starts
  const isAssignmentDataValid = () => {
    if (!assignmentData?.type) {
      errorHandler(
        `Please select ${AssessmentTypesResolver[assignmentData.type]} type!`
      );
      return false;
    }
    if (!assignmentData?.subType) {
      errorHandler(
        `Please select ${AssessmentTypesResolver[assignmentData.type]} subtype!`
      );
      return false;
    }
    if (!assignmentData?.title) {
      errorHandler(
        `Please enter ${AssessmentTypesResolver[assignmentData.type]} title!`
      );
      return false;
    }
    if (assignmentData?.tags.length === 0) {
      errorHandler(
        `Please select ${AssessmentTypesResolver[assignmentData.type]} topics!`
      );
      return false;
    }
    if (assignmentData?.marks <= 0) {
      errorHandler(
        `Please provide non zero marks for ${
          AssessmentTypesResolver[assignmentData.type]
        }!`
      );
      return false;
    }
    if (assignmentData?.subType === 1 && assignmentData?.weightage <= 0) {
      errorHandler(
        `Please provide non zero weightage for ${
          AssessmentTypesResolver[assignmentData.type]
        }!`
      );
      return false;
    }
    if (!assignmentData?.dueDate) {
      errorHandler(
        `Please provide a valid due date for ${
          AssessmentTypesResolver[assignmentData.type]
        }!`
      );
      return false;
    }
    if (!assignmentData?.difficulty) {
      errorHandler(
        `Please provide a difficulty level for ${
          AssessmentTypesResolver[assignmentData.type]
        }!`
      );
      return false;
    }
    if (
      ((assignmentData?.type === 2 || assignmentData?.type === 3) &&
        assignmentData?.time < 10) ||
      assignmentData?.time >
        (assignmentData?.type === 2 // Exam
          ? 180
          : assignmentData?.type === 3 // Quiz
          ? 60
          : 1440)
    ) {
      errorHandler(
        `Please provide a valid duration for ${
          AssessmentTypesResolver[assignmentData.type]
        }! Minimun time can be 10 minutes and Maximum time can be ${
          assignmentData?.type === 2 // Exam
            ? 180
            : assignmentData?.type === 3 // Quiz
            ? 60
            : 1440
        }`
      );
      return false;
    }

    return true;
  };

  const isAssignmentUpdateable = (activeStep) => {
    if (activeStep === 0) {
      if (!Boolean(assignmentId)) {
        errorHandler(
          `${
            AssessmentTypesResolver[assignmentData.type]
          } identity isn't provided!`
        );
        return false;
      }
      if (assignmentData?.subType === 1 && assignmentData?.weightage <= 0) {
        errorHandler(
          `Please provide non zero weightage for ${
            AssessmentTypesResolver[assignmentData.type]
          }!`
        );
        return false;
      }
      return true;
    } else if (activeStep === 1) {
      // if activeStep is 1

      if (!Boolean(assignmentId)) {
        errorHandler(
          `${
            AssessmentTypesResolver[assignmentData.type]
          } identity isn't provided!`
        );
        return false;
      }
      if (assignmentData?.questions?.length === 0) {
        errorHandler(
          `${
            AssessmentTypesResolver[assignmentData.type]
          } questions must be provided!`
        );
        return false;
      }
      return true;
    } else if (activeStep === 2) {
      // if activeStep is 2

      if (!Boolean(assignmentId)) {
        errorHandler(
          `${
            AssessmentTypesResolver[assignmentData.type]
          } identity isn't provided!`
        );
        return false;
      }
      if (assignmentData?.questions?.length === 0) {
        errorHandler(
          `${
            AssessmentTypesResolver[assignmentData.type]
          } questions must be provided!`
        );
        return false;
      }
      if (
        assignmentData?.questions?.length !==
        assignmentData?.questionMarks?.length
      ) {
        errorHandler(
          `${
            AssessmentTypesResolver[assignmentData.type]
          } questions marks must be provided!`
        );
        return false;
      }

      const assignedMarks = assignmentData?.questionMarks?.reduce(
        (total, obj) => total + Number(obj.marks),
        0
      );
      if (assignmentData?.marks !== assignedMarks) {
        errorHandler(
          `${
            AssessmentTypesResolver[assignmentData.type]
          } assigned marks must be equal to total marks!`
        );
        return false;
      }
      return true;
    }
  };

  const isAssignmentPublishable = () =>
    assignmentId &&
    (assignmentStudents?.studentIds?.length || assignmentStudents?.all);

  const areFiltersSet = () => {
    return Boolean(
      questionsFilters?.courseId &&
        (questionsFilters?.questionType ||
          questionsFilters?.count?.[1] ||
          questionsFilters?.questionDificulty ||
          questionsFilters?.tags?.length ||
          questionsFilters?.author ||
          questionsFilters?.keywords?.length)
    );
  };

  const cleanFilters = () => {
    const copy = JSON.parse(JSON.stringify(questionsFilters));

    if (copy?.keywords?.length === 0) {
      copy.keywords = null;
    }
    if (copy?.tags?.length === 0) {
      copy.tags = null;
    }
    if (copy?.count?.length === 0) {
      copy.count = null;
    }

    return copy;
  };
  // Validations Ends

  // API inteaction Starts
  const createAssignment = async () => {
    try {
      // validate data
      if (isAssignmentDataValid()) {
        setLoading(true);
        const response = await agent.Assessments.createAssessment(
          assignmentData
        );

        if (response.code === 201) {
          setAssignmentId(response?.data?.id);
          successHandler("Assessment created!");
        }
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    } finally {
      setLoading(false);
    }
  };

  const updateAssignment = async (activeStep) => {
    try {
      // validate data
      if (isAssignmentUpdateable(activeStep)) {
        setLoading(true);
        const response = await agent.Assessments.updateAssessment(
          assignmentId,
          assignmentData
        );

        if (response.code === 200) {
          successHandler("Assessment updated!");
        }
        return true;
      } else {
        // errorHandler(t("errors.questionSelection"));
        return false;
      }
    } catch (error) {
      return false;
    } finally {
      setLoading(false);
    }
  };

  const publishAssignment = async () => {
    try {
      // validate data
      if (isAssignmentPublishable()) {
        setLoading(true);
        const publishAssignment = {
          assessmentId: assignmentId,
          courseId: assignmentData?.courseId,
          ...assignmentStudents,
        };
        const response = await agent.Assessments.publishAssessment(
          publishAssignment
        );

        if (response.code === 201) {
          successHandler("Assessment published!");
        }
        setRefetch((prev) => !prev);
        return true;
      } else {
        errorHandler(t("errors.requiredFields"));
        return false;
      }
    } catch (error) {
      return false;
    } finally {
      setLoading(false);
    }
  };

  const getQuestionAuthors = async () => {
    try {
      const selectedCourseCode =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        assignmentData?.Course?.courseCode ?? assignmentData?.courseId;
      const courseCode = courses?.find(
        (c) =>
          c?.id === selectedCourseCode || c?.courseCode === selectedCourseCode
      )?.courseCode;
      if (courseCode) {
        const response = await agent.Questions.getQuestionAuthors(courseCode);

        if (response?.authors?.length) {
          setQuestionAuthors(response.authors);
        }
      }
    } catch (error) {}
  };

  const filterQuestions = async () => {
    try {
      // validate filters
      if (areFiltersSet()) {
        setIsFiltered(true);
        const cleanedFilters = cleanFilters();
        setLoading(true);
        const response = await agent.Questions.filterQuestion(cleanedFilters);

        if (response.code === 200) {
          successHandler("Question filtered!");
          setFilteredQuestion(response?.data);
        }
      } else {
        errorHandler(t("errors.filtersSelection"));
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleAutoQuestions = async () => {
    try {
      // validate auto quesions select input
      if (autoQuesions?.courseId && autoQuesions?.numberOfQuestions > 0) {
        setLoading(true);
        let autoSelectQuestions = { ...autoQuesions };
        if (isFiltered) {
          const cleanedFilters = cleanFilters();
          autoSelectQuestions = { ...cleanedFilters, ...autoQuesions };
        }
        const response = await agent.Questions.autoSelectQuestions(
          autoSelectQuestions
        );

        if (response.code === 200 && response?.data?.length) {
          successHandler("Questions auto selected!");
          setAssignmentData((prev) => ({
            ...prev,
            // Resetting sections data and marks assigned to questions
            sections: DEFAULT_ASSIGNMENT_DATA?.sections,
            questionMarks: DEFAULT_ASSIGNMENT_DATA?.questionMarks,
            questions: response?.data,
          }));
        } else {
          errorHandler(t("errors.noQuestionSelected"));
        }
      } else {
        errorHandler(t("errors.courseAndQuestionSelection"));
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getQuestionAuthors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, assignmentData?.courseId]);

  const questionsToShow = React.useMemo(() => {
    if (isFiltered) {
      return filteredQuestion;
    } else {
      return courseQuestions;
    }
  }, [isFiltered, filteredQuestion, courseQuestions]);

  // const finalisedQuestions = React.useMemo(() => {
  //   if (assignmentData?.autoGradeable) {
  //     return questionsToShow?.filter(
  //       (q) => q?.questionType === QuestionTypes.MCQ
  //     );
  //   } else {
  //     return questionsToShow;
  //   }
  // }, [assignmentData, questionsToShow]);

  const contextValues = {
    loading,
    tagsData,
    isFiltered,
    setRefetch,
    resetFilter,
    autoQuesions,
    selectedTags,
    assignmentId,
    addNewSection,
    removeSection,
    setIsFiltered,
    assignmentData,
    courseWeightage,
    handleTagChange,
    filterQuestions,
    handleTagDelete,
    setAutoQuesions,
    questionsAuthors,
    questionsFilters,
    createAssignment,
    updateAssignment,
    publishAssignment,
    updateSectionData,
    setAssignmentData,
    selectedFilterTags,
    assignmentStudents,
    setQuestionsFilters,
    resetAssignmentData,
    handleAutoQuestions,
    editDraftedAssignment,
    setAssignmentStudents,
    selectedQuestionsCount,
    handleFilterMultiChange,
    handleFilterMultiDelete,
    handleQuestionSelection,
    handleChangeQuestionMarks,
    addBulkQuestionsToSection,
    bulkQuestionSelectionForSection,
    clearbulkQuestionSelectionForSection,
    handleBulkQuestionSelectionForSection,
    questions: questionsToShow,
  };

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <NewAssignmentContext.Provider value={contextValues}>
      {children}
    </NewAssignmentContext.Provider>
  );
};

export { NewAssignmentContext };
export default NewAssignmentProvider;
